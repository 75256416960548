<template>
  <!-- <div > -->
  <div class="px-2 h-100" style="display: grid; place-content: flex-start">
    <!-- <div style="height: 86px"></div> -->
    <b-card title="Welcome 🚀" class="mt-5">
      <b-card-text>Welcome to Toqoo Material.</b-card-text>
    </b-card>
  </div>
  <!-- </div> -->
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  title() {
    return "Welcome 🚀";
  },
  components: {
    BCard,
    BCardText,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      data: Object,
    };
  },
  // eslint-disable-next-line consistent-return
  created() {
    const { phone_verified_at } = JSON.parse(localStorage.getItem("userData"));
    // eslint-disable-next-line camelcase
    if (!phone_verified_at) {
      return this.$router.push("/otp");
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.card-body {
  width: 100vw;
}
</style>
